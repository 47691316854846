.login-row {
  height: 100vh;
}

.navbar-brand img {
  height: 45px;
}

.navbar-brand span {
  vertical-align: middle;
  font-size: 30px;
}

.logo-separator {
  height: 50px;
}

@media screen and (max-width: 991px) {
  .logo-separator {
    height: 0px;
    margin-top: 10px;
  }
}

.personal .profile-pic {
  max-width: 100%;
}

button i {
  vertical-align: middle;
}

/* image upload */
.image-container {
  position: relative;
  width: 25%;
  height: 25%;
  border-radius: 10px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-icon {
  position: absolute;
  bottom: 4px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}

.edit-icon:hover {
  background: black;
}

.image-container .preview {
  position: absolute;
  bottom: 4px;
  left: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.image-container .cancel {
  position: absolute;
  bottom: 4px;
  left: 90px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

@media screen and (max-width: 900px) {
  .image-container {
    width: 100%;
    height: 100%;
  }
  .form-group button {
    width: 100%;
    margin: 2% 0;
  }
  .admin-logo {
    width: 33% !important;
  }
}
